import { HomeScreen } from './pages/HomeScreen';
import './App.css';
import 'leaflet/dist/leaflet.css'
import 'bootstrap/dist/css/bootstrap.min.css';


// import 'leaflet/dist/leaflet.css'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HomeScreen/>
      </header>
    </div>
  );
}

export default App;
