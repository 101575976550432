import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useMap, useMapEvent } from 'react-leaflet';
function ReactLeafletGoogleLayerMap({ coordinateCenter, whenReady, setMapContext, zoom, setZoomValue }) {

    // useMapEvent('zoomlevelschange', () => {
    // console.log('-----map-----', map.getZoom())
    // })
   
    const map = useMap();
    // console.log('map0000', map.getZoom())
    map.getZoom() != zoom && setZoomValue(map.getZoom())
    map.setView(coordinateCenter, zoom == 18 ? 18 : map.getZoom())
    setZoomValue(3)
    return (
        <>
            <ReactLeafletGoogleLayer
                apiKey="AIzaSyAy1qz9D55wuxWY0jmhhTWvRu9oeDl2V44"
                type={"hybrid"} // Replace with your desired map type
            />

        </>
    );
}
export default ReactLeafletGoogleLayerMap;