import { useEffect, useState, } from 'react';
// import { useMap } from 'react-leaflet'
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayerMap from './reactLeafletGoogleLayer';
function MapContainerCoordinates({coordinateCenter,whenReady,setMapContext,zoom,setZoomValue}) {
  // console.log('zoom is zoom ',zoom)
  // const map=useMap();
  return (
    <div className="offset-1 col-10 offset-1 px-md-5 px-2" style={{ height: "63vh" }}>
      {/* <MapWithPlaceholder /> */}
      <div >
        <MapContainer
          center={coordinateCenter}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{
            height: "60vh",
            width: "100%",
          }}
          whenReady={whenReady}
        >
          <ReactLeafletGoogleLayerMap coordinateCenter={coordinateCenter} zoom={zoom} setZoomValue={setZoomValue}/>
        </MapContainer>
      </div>
      <a href="#graph" className="position-absolute circle-arrow-bottom text-decoration-none text-dark">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
        </svg>
      </a>
      {/* <MapWithPlaceholder /> */}
    </div>
  );
}
export default MapContainerCoordinates;