export function roundToDecimalPlaces(num, precision) {
  return num.toFixed(precision);
}

export function roundCoordinates(coordinates, value) {
  const roundedCoordinates = [];

  for (let i = 0; i < coordinates.length; i++) {
    const roundedInnerCoordinates = [];

    for (let j = 0; j < coordinates[i].length; j++) {
      const roundedValue = roundToDecimalPlaces(coordinates[i][j], value);
      roundedInnerCoordinates.push(roundedValue);
    }

    roundedCoordinates.push(roundedInnerCoordinates);
  }

  return roundedCoordinates;
}
// export function handlePlayback(groupOfCoordinates_1x,groupOfCoordinates_2x,groupOfCoordinates_5x,playBack, precision) {
//   let roundedCoordinates;
//   if (playBack === 100000) {
//     console.log('%c reponse from handlePlayback function playbackvalue is ','color:brown',playBack)
//     roundedCoordinates = roundCoordinates(groupOfCoordinates_1x, precision);
//   } else if (playBack === 1000) {
//     console.log('%c reponse from handlePlayback function playbackvalue is ','color:brown',playBack)
//     roundedCoordinates = roundCoordinates(groupOfCoordinates_2x, precision);
//   } else {
//     console.log('%c reponse from handlePlayback function playbackvalue is ','color:brown',playBack)
//     roundedCoordinates = roundCoordinates(groupOfCoordinates_5x, precision);
//   }

//   console.log("%c <--------------------------->", roundedCoordinates);
//   return roundedCoordinates;
// }

export function handlePlayback(
  groupOfCoordinates_1x,
  groupOfCoordinates_2x,
  groupOfCoordinates_5x,
  altitude_1x,
  altitude_2x,
  altitude_5x,
  time_1x,
  time_2x,
  time_5x,
  playBack,
  previousPlayback,
  precision
) {
  return new Promise((resolve, reject) => {
    let roundedCoordinates;
    
    if (playBack === 100000) {
      roundedCoordinates = roundCoordinates(groupOfCoordinates_1x, precision);
      resolve({ roundedCoordinates, altitude: altitude_1x, time: time_1x });
    } else if (playBack === 1000) {
      roundedCoordinates = roundCoordinates(groupOfCoordinates_2x, precision);
      resolve({ roundedCoordinates, altitude: altitude_2x, time: time_2x });
    } else {
      roundedCoordinates = roundCoordinates(groupOfCoordinates_5x, precision);
      resolve({ roundedCoordinates, altitude: altitude_5x, time: time_5x });
    }

    // console.log("%c <--------------------------->", roundedCoordinates);np
  });
}
export function PlaybackIndexFInder(
  time_1x,
  time_2x,
  time_5x,
  previousPlayback,
  index
) {
  return new Promise((resolve, reject) => {
    if (previousPlayback === 100000) {
      resolve({ time: time_1x[index], index: index,label:'time_1x[index]' });
    } else if (previousPlayback === 1000) {
      resolve({ time: time_2x[index], index: index,label:'time_2x[index]' });
    } else {
      resolve({ time: time_5x[index], index: index,label:'time_5x[index]' });
    }
  });
}
export function PlaybackTimeIndexFInder(
  time_1x,
  time_2x,
  time_5x,
  playBack,
  time
) {
  return new Promise((resolve, reject) => {
    if (playBack === 100000) {
      const greaterValue = time_1x.find(
        (value) => new Date(value) > new Date(time)
      );
      const index = time_1x.indexOf(greaterValue);
      resolve({ time: time_1x[index], index: index,label:'time_1x[index]' });
    } else if (playBack === 1000) {
      const greaterValue = time_2x.find(
        (value) => new Date(value) > new Date(time)
      );
      const index = time_2x.indexOf(greaterValue);
      resolve({ time: time_2x[index], index: index,label:'time_2x[index]' });
    } else {
      const greaterValue = time_5x.find(
        (value) => new Date(value) > new Date(time)
      );
      const index = time_5x.indexOf(greaterValue);
      resolve({ time: time_5x[index], index: index,label:'time_5x[index]' });
    }
  });
}

/* get current time */
export const getCurrentDateTime = () => {
  const currentDate = new Date();

  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);
  const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return Promise.resolve(formattedDateTime);
};
