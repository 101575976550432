import React, { useEffect, useRef, useState } from "react";
// import faker from 'faker';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Line } from "react-chartjs-2";
import "./Graph.css";

ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, zoomPlugin
);

export const Graph = ({
  realtimeState,
  real_time,
  real_time_altitude
}) => {


  const delayBetweenPoints = 100;
  var labels = real_time

  const [data_, setdata] = useState({
    labels,
    datasets: [
      {
        fill: true,
        label: "Altitude",
        data: real_time_altitude,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  })


  const options = {
    responsive: true,
    animations: {
      custom: {
        fn: (context) => {
          const delay = 0;
          const index = context.dataIndex;
          const chart = context.chart;

          if (!chart.started[index]) {
            chart.started[index] = true;
            delay = index * delayBetweenPoints;
          }

          const previousData =
            index > 0
              ? chart
                .getDatasetMeta(0)
                .data[index - 1].getProps(["x", "y"], true)
              : { x: 0, y: chart.scales.y.getPixelForValue(100) };

          return {
            x: {
              easing: "linear",
              duration: delayBetweenPoints,
              from: previousData.x,
              delay,
            },
            y: {
              easing: "linear",
              duration: delayBetweenPoints * 500,
              from: previousData.y,
              delay,
            },
            skip: {
              type: "boolean",
              duration: delayBetweenPoints,
              from: true,
              to: false,
              delay,
            },
          };
        },
      },
    },
    scales: {
      x: {
        min: real_time.length == 0 ? '2023-09-17T00:07:24.000Z' : real_time[0],
        max: real_time.length == 0 ? '2023-09-17T00:01:42.000Z' : real_time.length > 12 ? real_time[11] : real_time[real_time.length - 1],
      },
      y: {
        max: real_time_altitude.length == 0 ? 0 : Math.max(...real_time_altitude) + 1,
        min: real_time_altitude == 0 ? 250 : Math.min(...real_time_altitude) - 1,
        stacked: true,
        title: {
          display: true,
          text: "meters",
        },

      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "timeline altitude",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
          limits: {
            x: { min: 0, max: 20 },
          },
        },
      },
    },
  };


  useEffect(() => {
    if (realtimeState === true && real_time?.length > 0) {
      if (real_time_altitude.length < 15) {
        setdata({
          labels,
          datasets: [
            {
              fill: true,
              label: "Altitude",
              data: real_time_altitude,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        })
      }
    }
  }, [realtimeState, real_time_altitude]);
  return (
    <div id="graph">
      <span className="fs-4 fw-bold text-gray">Altitude</span>
      <div className="my-3">
        <Line options={options} data={data_} updateMode="active" />
      </div>
    </div>
  );
};