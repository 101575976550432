import React, { useEffect, useState, useReducer, useRef } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import L from "leaflet";
import MapContainerCoordinates from "../components/molecule/map/mapContainer";
import { DatePickerSelector, Graph } from "../components/index";
import { images } from "../assets/image";
import { backend_url } from "../api/api";
import {  handlePlayback, PlaybackIndexFInder, PlaybackTimeIndexFInder} from "../util/functions";
import "./HomeScreen.css";
import "leaflet/dist/leaflet.css";
import "leaflet.motion/dist/leaflet.motion.js";


const reducer = (state, action) => {
  switch (action.type) {
    case 'apiCallenabled':
      return { real_Time_State: true, isloading: true };
    case 'apiCalldisabled':
      return { real_Time_State: true, isloading: false };
    case 'enabled':
      return { real_Time_State: true, isloading: false };
    case 'disabled':
      return { real_Time_State: false, isloading: false };

    default:
      return state;
  }
};

/* this are the comments added in code */
/* custom function */
/* variable declartion */
/* leaflet config and functions */

export const HomeScreen = () => {
  // const gmap = useMap()
  const [state, dispatch] = useReducer(reducer, { isloading: false, real_Time_State: false });
  const realtimeStateRef = useRef(state.real_Time_State);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);
  const [zoomValue, setZoomValue] = useState(3);
  const [playBack, setplayBack] = useState(100000);
  const previousPlayback = useRef(playBack);
  const [index_, setIndex_] = useState(0)
  const indexRef = useRef(index_)
  const playBackRef = useRef(playBack)
  const [smooth, setsmooth] = useState(5);
  const smoothRef = useRef(smooth)
  const [fromDate, setFromDate] = useState("01/01/2023");
  const [toDate, setToDate] = useState("01/12/2023");
  const [coordinateCenter, setCoordinateCenter] = useState([
    -37.807447, 144.971642,
  ]);
  const [mapContext, setMapContext] = useState();
  const [selectedDates, setSelectedDates] = useState([]);
  const [altitude_1x, setAltitude_1x] = useState([]);
  const [altitude_2x, setAltitude_2x] = useState([]);
  const [altitude_5x, setAltitude_5x] = useState([]);
  const [time_1x, setTime_1x] = useState([]);
  const [time_2x, setTime_2x] = useState([]);
  const [time_5x, setTime_5x] = useState([]);
  const [groupOfCoordinates_1x, setgroupOfCoordinates_1x] = useState([]);
  const [groupOfCoordinates_2x, setgroupOfCoordinates_2x] = useState([]);
  const [groupOfCoordinates_5x, setgroupOfCoordinates_5x] = useState([]);


  const [index, setIndex] = useState(0)

  const [realtimeState, setRealtimeState] = useState(false)
  const [real_time, setReal_time] = useState([]) // useState(['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',])
  const [real_time_altitude, setReal_time_altitude] = useState([]) // useState([20, 45, 71, 1, 1, 10, 71, 88, 30])

  /* variable delcaration */
  let instance = null;
  let previous_coordinate = [];

  // const mapRef = useRef(null);


  /* leaflet config and functions --start */



  let previousMarker = null; // Keep track of the previous marker
  let previousCoordinates = null
  const updateMarker = (coordinates,angle) => {
    // Remove the previous marker if it exists
    setCoordinateCenter(coordinates[0])
    // gmap.setView(coordinates[0], gmap.getZoom());
    if (previousMarker) {
      mapContext && mapContext.removeLayer(previousMarker);
      const pre_instance = L.polyline(
        [previousCoordinates],
        {
          color: "blue",
        }
      );
      mapContext && mapContext.addLayer(pre_instance);
      setMapLayers((prevLayers) => [...prevLayers, pre_instance]);
    }

    const instance = L.motion.polyline(
      [coordinates],
      {
        color: "blue",
      },
      {
        auto: true,
        duration: 1000,
      },
      {
        removeOnEnd: false,
        showMarker: true,
        icon: L.divIcon({
          className: "aeroplane-icon",
          html: `
          <img src="${images.aeroplaneIcon}" class="aeroplane-icon-image" style="transform:rotate(${angle?angle:'40deg'}) !important"/>
          <div class="speed-meter">100 km/h</div>
        `,
          iconSize: [48, 48],
          rotation: 80
        }),
      }
    );
    // console.log('instance',instance)
    // instance.options.icon.options.rotation = 120;
    if (realtimeStateRef.current) {
      setMapLayers((prevLayers) => [...prevLayers, instance]);
      mapContext && mapContext.addLayer(instance);
    } else {
      console.log('update marker value plotting end')
    }

    // Set the current marker as the previous marker
    previousMarker = instance;
    previousCoordinates = coordinates;
  };




  const clearPolyline = () => {
    setgroupOfCoordinates_1x([]);
    setRealtimeState(false)
    // dispatch({type:'disabled'})

    for (let i = 0; i < mapLayers.length; i++) {
      const layer = mapLayers[i];
      mapContext && mapContext.removeLayer(layer);
    }
    // setMapLayers([]);
  };

 
  /* leaflet config and functions --end */

  /* api calling --start */
  const find_cordinates = async ({ fromTimeZone, toTimeZone }) => {
    clearPolyline()
    // dispatch({ type: "enabled" });
    // setRealtimeState(true);
    setLoading(true);

    const createRequest = async (interval) => {
      return new Promise((resolve, reject) => {
        var data = JSON.stringify({
          from_: fromTimeZone,
          to_: toTimeZone,
          interval_: interval,
        });

        var config = {
          method: "post",
          url: backend_url + "/locations",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    try {
      const [response1, response2, response3] = await Promise.all([
        createRequest(1),
        createRequest(3),
        createRequest(5),
      ]);

      setZoomValue(18);

      /* 1x */
      response1?.features[0]?.geometry?.coordinates &&
        setgroupOfCoordinates_1x(response1?.features[0]?.geometry?.coordinates);
      response1?.features[0]?.geometry?.altitude &&
        setAltitude_1x(response1?.features[0]?.geometry?.altitude);
      response1?.features[0]?.properties?.start_point &&
        setCoordinateCenter(response1?.features[0]?.properties?.start_point);
      response1?.features[0]?.geometry?.time &&
        setTime_1x(response1?.features[0]?.geometry?.time);

      if (response1?.features[0]?.geometry?.coordinates?.length > 0) {
        dispatch({ type: "enabled" });
        setRealtimeState(true);
        realtimeStateRef.current = true;
        // console.table('time1x', response1?.features[0]?.geometry?.time, 'time2x', response2?.features[0]?.geometry?.time, 'time 5x', response3?.features[0]?.geometry?.time)
      }

      /* 2x  here 2x change to 3x but the variable are not changing please understand change*/

      response2?.features[0]?.geometry?.coordinates &&
        setgroupOfCoordinates_2x(response2?.features[0]?.geometry?.coordinates);
      response2?.features[0]?.geometry?.altitude &&
        setAltitude_2x(response2?.features[0]?.geometry?.altitude);
      response2?.features[0]?.properties?.start_point &&
        setCoordinateCenter(response2?.features[0]?.properties?.start_point);
      response2?.features[0]?.geometry?.time &&
        setTime_2x(response2?.features[0]?.geometry?.time);

      /* 5x */

      response3?.features[0]?.geometry?.coordinates &&
        setgroupOfCoordinates_5x(response3?.features[0]?.geometry?.coordinates);
      response3?.features[0]?.geometry?.altitude &&
        setAltitude_5x(response3?.features[0]?.geometry?.altitude);
      response3?.features[0]?.properties?.start_point &&
        setCoordinateCenter(response3?.features[0]?.properties?.start_point);
      response3?.features[0]?.geometry?.time &&
        setTime_5x(response3?.features[0]?.geometry?.time);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 404) {
        alert.info('No Data Found')
      }
      console.error(error);
    }
  };



  /* api calling --end */

  const checkRealTimeState = () => {
    return new Promise((resolve, reject) => {
      // console.log(realtimeStateRef, `!!!!!!!!!!!!!!!!!!!!!!!!!!`, realtimeState)
      if (realtimeStateRef.current) {
        resolve();
      } else {
        real_time_altitude.splice(0, real_time_altitude.length)
        real_time.splice(0, real_time.length)
        reject();
      }
    });
  };


  useEffect(() => {
    // console.log(realtimeState, '$ 000000', real_time_altitude, real_time);

    const mapCoordinates = async (coordinates_, altitude_, time_, index_) => {

      // console.log(coordinates_[0], altitude_[0], time_[0], index)

      if (indexRef.current >= coordinates_.length) {
        console.log('find coordinate map function end');
        return;
      }

      setTimeout(() => {

        checkRealTimeState()
          .then(() => {
            // console.log('real_Time_State is true', coordinates[index]);
            if (playBackRef.current != previousPlayback.current) {
              PlaybackIndexFInder(time_1x,
                time_2x,
                time_5x,
                previousPlayback.current,
                indexRef.current).then(e => {

                  PlaybackTimeIndexFInder(
                    time_1x,
                    time_2x,
                    time_5x,
                    playBackRef.current,
                    e.time
                  ).then(e => {
                    indexRef.current = e.index
                    handlePlayback(groupOfCoordinates_1x, groupOfCoordinates_2x, groupOfCoordinates_5x, altitude_1x, altitude_2x, altitude_5x, time_1x, time_2x, time_5x, playBackRef.current, previousPlayback.current, smoothRef.current)
                      .then(({ roundedCoordinates, altitude, time }) => {

                        // console.log('Promise resolved:', roundedCoordinates);

                        logCoordinateWithDelay(roundedCoordinates[indexRef.current], indexRef.current); //update marker 
                        graphDrawingwithDelay(altitude[indexRef.current], time[indexRef.current], indexRef.current); //graph plotting
                        previousPlayback.current = playBackRef.current
                        indexRef.current = indexRef.current + 1
                        mapCoordinates(roundedCoordinates, altitude, time, indexRef.current);
                        // Handle the resolved value here
                      })

                  })

                })
            } else {
              // playBackCoordinatesPlotting()
              handlePlayback(groupOfCoordinates_1x, groupOfCoordinates_2x, groupOfCoordinates_5x, altitude_1x, altitude_2x, altitude_5x, time_1x, time_2x, time_5x, playBackRef.current, previousPlayback.current, smoothRef.current)
                .then(({ roundedCoordinates, altitude, time }) => {

                  // console.log('Promise resolved:', roundedCoordinates);
                  logCoordinateWithDelay(roundedCoordinates[indexRef.current], indexRef.current); //update marker 
                  graphDrawingwithDelay(altitude[indexRef.current], time[indexRef.current], indexRef.current); //graph plotting

                  indexRef.current = indexRef.current + 1
                  mapCoordinates(roundedCoordinates, altitude, time, indexRef.current);
                  // Handle the resolved value here
                })
            }

          })
          .catch(() => {
            console.log('find coordinate map function end');
            // Handle the reject case here
          });

      }, 1000);
    };

    // realtimeState && mapCoordinates(playBack === 100000 ? groupOfCoordinates_1x : playBack === 1000 ? groupOfCoordinates_2x : groupOfCoordinates_5x, 0);//here zero represet index value
    realtimeState && handlePlayback(groupOfCoordinates_1x, groupOfCoordinates_2x, groupOfCoordinates_5x, altitude_1x, altitude_2x, altitude_5x, time_1x, time_2x, time_5x, playBack, smooth)
      .then(({ roundedCoordinates, altitude, time }) => {
        // console.log('Promise resolved:', roundedCoordinates);
        mapCoordinates(roundedCoordinates, altitude, time, 0)
        // Handle the resolved value here
      })
      .catch((error) => {
        console.error('Promise rejected:', error);
        // Handle any potential errors here
      });
  }, [realtimeState]);




  useEffect(() => {
    var config = {
      method: "get",
      url: backend_url + "/getdate",
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setFromDate(response.data.startDate.date);
        setToDate(response.data.endDate.date);
        // setTime_1xZone(dateC)
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);


  /* custom function --start */

  const calculateRotation = (previousCoordinates) => {
   return new Promise((resolve, reject) => {
      if (previousCoordinates[0].length > 0 && previousCoordinates[1].length > 0) {
        const deltaY = previousCoordinates[1][0] - previousCoordinates[0][0];
        const deltaX = previousCoordinates[1][1] - previousCoordinates[0][1];
      
        // Calculate the angle in degrees
        let angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
        // Adjust the angle to be in the range [0, 360)

        angle = (angle + 360) % 360;

        console.log('previous command',angle);
        angle=angle>0?360-angle:360+angle;
        console.log('angle value is',angle)
        resolve (angle+80+"deg");
      }
    })
  
  };

  function logCoordinateWithDelay(coordinate) {
    setTimeout(() => {
      // console.log('coordinates in  logcoordinate with delay', coordinate)
      if (previous_coordinate.length === 0) {
        previous_coordinate = [coordinate, coordinate]
        // clearPolyline()
        setTimeout(() => {

          if (realtimeStateRef.current == true) {
            // calculateRotation(previousCoordinates).then(({angle})=>{
            updateMarker(previous_coordinate);
            // })
          }
        }, 1000);

      } else if (coordinate.length > 2) {
        previous_coordinate.shift();
        // console.log(`Coordinate ----- f : (${previous_coordinate})`);
        previous_coordinate.push(coordinate)
        setCoordinateCenter(previous_coordinate);
        // console.log(`<------------------------> ----- f : (${previous_coordinate})`);
        // clearPolyline()
        setTimeout(() => {

          if (realtimeStateRef.current == true) {
            // calculateRotation(previous_coordinate[0][0], previous_coordinate[0][1], previous_coordinate[1][0], previous_coordinate[1][1])
            // calculateRotation(previousCoordinates).then(({angle})=>{
            updateMarker(previous_coordinate);
            // })
            previous_coordinate.shift(coordinate.length - 2);
          }
        }, 1000);
      } else {
        // console.log(`Coordinate ${index + 1}: (${previous_coordinate})`);
        previous_coordinate.shift();
        // console.log(`Coordinate ----- f : (${previous_coordinate})`);
        previous_coordinate.push(coordinate)
        setCoordinateCenter(previous_coordinate[1]);
        // console.log(`<------------------------> ----- f : (${previous_coordinate})`);
        // clearPolyline()
        setTimeout(() => {

          if (realtimeStateRef.current == true) {
            calculateRotation(previousCoordinates).then((angle)=>{
              // console.log('angle valuje is ',angle)
            updateMarker(previous_coordinate,angle);
            })
          }
        }, 1000);

      }
    }, 1000)
    // Multiply index by 1000 to create a 1-second interval
  }
  function graphDrawingwithDelay(altitude, time, index) {
    setTimeout(() => {
      if (real_time_altitude.length === 0 && real_time.length === 0) {
        // console.log('time,altitude', time, altitude)
        setReal_time(time)
        setReal_time_altitude(altitude)
        // console.log('time,altitude', real_time, real_time_altitude)

      } else {
        // console.log('time,altitude', real_time, real_time_altitude)
        real_time_altitude.length > 17 && real_time_altitude.shift()
        real_time_altitude.push(altitude)

        real_time.length > 17 && real_time.shift()
        real_time.push(time)


      }
    }, 1000)
  }
  function clearPolylineAndSetState() {
    return new Promise((resolve, reject) => {
      try {
        clearPolyline();
        realtimeStateRef.current = false;
        indexRef.current = 0;
        setRealtimeState(false);
        dispatch({ type: 'disabled' });
        real_time_altitude.splice(0, real_time_altitude.length)
        real_time.splice(0, real_time.length)
        resolve('Success: Polyline cleared and state set');
      } catch (error) {
        reject(`Error: ${error}`);
      }
    });
  }

const handleSliderChange = (event) => {
  const newValue = event.target.value;

  if (newValue >= 3 && newValue < 6) {
    setsmooth(4);
    smoothRef.current = 4;
  } else if (newValue > 6 && newValue <= 8) {
    setsmooth(8);
    smoothRef.current = 8;
  } else if (newValue => 6 && newValue < 8 && smooth==8) {
    setsmooth(6);
    smoothRef.current = 5;
  }  
  else {
    setsmooth(6);
    smoothRef.current = 5;
  }
};
  /* custom function --end */

  /* smoothing functions */


  return (
    <div>


      <div className="home-screen-container h-100">
        <div className="row py-1">
          <div className="col-md-12 d-flex  justify-content-center d-flex">
            <form className="row g-3">
              <div className="align-self-center px-5 py-1">
                <div className="d-flex py-1 ">
                  <div className="px-3">
                    <label className="px-1">From</label>
                    <DatePickerSelector
                      className="from-date"
                      fromDate={fromDate}
                      toDate={toDate}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                    />
                  </div>
                  <div className="px-3">
                    <label className="px-1">To</label>
                    <DatePickerSelector
                      className="to-date"
                      fromDate={fromDate}
                      toDate={toDate}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-dark mx-3"
                    disabled={loading ? true : false}
                    onClick={(e) => {
                      e.preventDefault();

                      clearPolylineAndSetState()
                        .then((e) => {

                          // handlePolylineDraw();
                          // setRealtimeState(false)
                          dispatch({ type: 'disabled' })
                          let fromTimeZone =
                            document.getElementsByClassName("rmdp-input")[0].value;
                          let toTimeZone =
                            document.getElementsByClassName("rmdp-input")[1].value;
                          if (fromTimeZone === toTimeZone) {
                            alert.info("BOTH VALUES ARE SAME. PLEASE CHANGE ");
                          } else if (
                            fromTimeZone === undefined ||
                            toTimeZone === undefined
                          ) {
                            alert.info("SELECT VALUE FOR FROM AND TO ");
                          } else {
                            find_cordinates({ fromTimeZone, toTimeZone });
                            // handleAddMarkerClick()handleAddMarkerClick
                          }
                        })
                    }}
                  >
                    {loading ? (
                      <svg
                        id="infinite-indicator"
                        className="mx-2"
                        width="24"
                        height="24"
                        viewBox="0 0 100 100"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          r="40"
                          cx="50"
                          cy="50"
                          style={{
                            fill: "none",
                            stroke: "#fff",
                            strokeWidth: 10,
                            strokeOpacity: 0.6,
                          }}
                        ></circle>
                        <path
                          d="M10 50 A40 40 0 1 1 90 50"
                          style={{
                            fill: "none",
                            stroke: "#fff",
                            strokeWidth: 10,
                          }}
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    ) : (
                      "Track"
                    )}
                  </button>
                  <button className="btn btn-warning" onClick={(e) => { e.preventDefault(); clearPolyline(); realtimeStateRef.current = false; setRealtimeState(false); dispatch({ type: 'disabled' }); real_time_altitude.splice(0, real_time_altitude.length); real_time.splice(0, real_time.length) }}
                  >
                    clear
                  </button>
                

                </div>
              </div>
            </form>
          </div>

          {/* group btn start */}
          <div className="offset-1 col-10 offset-1 px-md-5 px-2 py-3 d-flex justify-content-between">
         
         {/* slider button start */}
            <div className="display-grid">
              <label className="fw-bold fs-6 lh-lg">Smoothness</label>

              <div className="position-relative">
                <div class="progress" />
                <input type="range"
                  id="custom-slider"
                  min="4"
                  max="8"
                  step="1"
                  value={smooth == 5 ? 6 : smooth}
                  onChange={handleSliderChange} />
              </div>
            </div>
         {/* slider button end */}


            <div className="display-grid">
              <label className="fw-bold fs-6 lh-lg">Speed</label>
              <div
                className="btn-group gap-1"
                role="group"
                aria-label="Playback Speed"
              >
                <button type="button" className={playBack === 100000 ? 'btn btn-dark' : `btn border-1 border border-dark`}
                  onClick={() => {
                    setplayBack(prevPlayBack => 100000);
                    playBackRef.current = 100000
                    alert.info("please wait data fetching processing")
                  }}>
                  1x
                </button>
                <button type="button" className={playBack === 1000 ? 'btn btn-dark' : `btn border-1 border border-dark`}
                  onClick={() => {
                    setplayBack(prevPlayBack => 1000);
                    playBackRef.current = 1000
                    alert.info("please wait ")
                 }}>
                  3x
                </button>
                <button type="button" className={playBack === 500 ? 'btn btn-dark' : `btn border-1 border border-dark`}
                  onClick={() => {
                    setplayBack(prevPlayBack => 500);
                    playBackRef.current = 500
                    alert.info("please wait ")
                  }}>
                  5x {/* 5x button */}
                </button>
              </div>
            </div>
          </div>
          {/* group btn end */}

         <MapContainerCoordinates coordinateCenter={coordinateCenter} whenReady={(event) => setMapContext(event.target)} zoom={zoomValue} setZoomValue={setZoomValue} />

          <div
            className="offset-1 col-10 offset-1 px-md-5 px-2 "
            style={{ height: "70vh" }}
          >
            <Graph
              realtimeState={state.real_Time_State}
              real_time={real_time}
              real_time_altitude={real_time_altitude}
            />

          </div>
        </div>
      </div>


    </div>
  );
};
