import React, { useEffect, useState } from 'react'
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";

import './TimePicker.css'

export const DatePickerSelector = ({ fromDate, toDate,selectedDates,setSelectedDates }) => {
    // console.log('toDate',toDate,'fromDate',fromDate)
    const enabledDates = [new Date('2023-05-20'), new Date('2023-05-21'), new Date('2023-05-22'), new Date('2023-05-23'), new Date('2023-05-25'), new Date('2023-05-28')];


    const handleDateSelect = (e) => {
        console.log('handleDateSelect value is -->',e.target.value)
        setSelectedDates(e.target.value);
    };

    const minDate = new Date('2023-01-01');
    const maxDate = new Date('2023-12-31');
    return (<DatePicker
        // disabled={new Date('2023-05-20')}
        value={fromDate}
        // onChange={setSelectedDates}
        // range
        minDate={fromDate}
        maxDate={toDate}
        className="custom-datepicker"
        // render={<Calendar disabled={isDateDisabled} />}
        // numberOfMonths={2}
        format="YYYY/MM/DD HH:mm:ss"
        plugins={[<TimePicker />]}
    />

    )
}
