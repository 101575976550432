//live

//devel

export const backend_url='https://uptime-be.zealind.com'

//local

// export const backend_url='http://localhost:8001'

//dev 

// export const backend_url='https://uptime-mw.hzdev.tridz.in'